import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Table, Row, Result, Button, Col, Layout } from 'antd';

// import { fetchMachineLists } from './machinesListSlice';


import moment from 'moment';
// import AddMachines from './addMachines';
// import { useNavigate, useParams } from 'react-router-dom';
import SingleMachine from '../machine/machine';
import AddMachine from './addMachine';

const { Content } = Layout;


const MachinesList = () => {

    // let navigate = useNavigate();

    // const dispatch = useDispatch();
    let fetchServerErr = useSelector(
        (state) => state.machines.fetchServerErr
    );

    const fetchMachineListResult = useSelector(
        (state) => state.machines.fetchMachineListResult
    );

    const isFetchingMachinesList = useSelector(
        (state) => state.machines.isFetchingMachinesList
    );
    const isAddingNewMachineresult = useSelector(
        (state) => state.machines.isAddingNewMachineresult
    );

    const [visible, setVisible] = useState(false)
    const [openDrawer, setOpenDrawer] = useState(false);
    const [machineID, setMachineID] = useState(null);

    const columns = [
        {
            title: 'Nom de la machine',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'L\'identifiant',
            dataIndex: 'equipment_id',
            // width: "10%",
            key: 'equipment_id',
        },
        {
            title: 'Le nom local',
            dataIndex: 'equipment_local',
            // width: "10%",
            key: 'equipment_local',
        },
        {
            title: 'Numéro de série',
            dataIndex: 'serial_number',
            // width: "10%",
            key: 'serial_number',
        },
        {
            title: 'Motif de panne récurrente',
            dataIndex: 'recurring_breakdown_reason_history',
            width: "20%",
            key: 'recurring_breakdown_reason_history',
            render: (data) => (<p style={{ whiteSpace: "pre-line" }}>{data}</p>),

        },
        {
            title: 'Année de construction',
            dataIndex: 'construction_date',
            key: 'construction_date',
            // width: "10%",

            render: (data) => (<p>{data ? moment(data).format("DD/MM/YYYY") : ""}</p>),

        },

        {
            title: "Actions",
            align: "center",
            dataIndex: "_id",
            key: "_id",
            // width: "10%",

            render: (_id) => {
                return (
                    <Button
                        type="link"
                        // icon={<EditOutlined />}
                        size="small"
                        onClick={(e) => {
                            setOpenDrawer(true)
                            setMachineID(_id)
                        }}
                    >
                        Gérer
                    </Button>
                );
            },
        },

    ];


    const onClose = () => {
        setOpenDrawer(false);
    };


    return (

        <Content
            className="site-layout-background"
            style={{
                padding: 0,
                margin: 0,
                minHeight: 280,
            }}
        >
            {fetchServerErr ?
                <Result
                    status="500"
                    title="500"
                    subTitle="Une erreur inattendue est survenue, veuillez réessayer plus tard !"
                />
                : <>
                    <SingleMachine openDrawer={openDrawer} onClose={onClose} machineID={machineID} />

                    <AddMachine visible={visible} setVisible={setVisible} />

                    <Row justify={"center"} style={{ background: "black", minHeight: "80px", alignContent: "center" }}
                    >
                        <Col span={5} style={{ paddingTop: 10, display: 'flex', alignContent: "center" }}                >
                            {/* <Text style={{ color: "white", fontSize: "16px" }}>Ajouter une machine</Text> */}
                            <Button type='primary'
                                onClick={() => { setVisible(true) }}
                                style={{ width: "200px", height: "32px" }}> Nouvelle machine</Button>
                        </Col>
                    </Row>
                    <Row justify={"center"} style={{ marginTop: "24px" }}>
                        <Col span={24} style={{ backgroundColor: "white" }} >

                            {fetchMachineListResult && fetchMachineListResult.data ?
                                <Table
                                    bordered
                                    style={{ width: "100%", overflow: "auto" }}
                                    columns={columns}
                                    size='small'
                                    loading={isFetchingMachinesList || isAddingNewMachineresult}
                                    dataSource={fetchMachineListResult ? fetchMachineListResult.data : null}
                                    pagination={false} // This hides pagination
                                />
                                :
                                <Table style={{ width: "100%" }} columns={columns}

                                    loading={isFetchingMachinesList || isAddingNewMachineresult} dataSource={null} />
                            }
                        </Col>
                    </Row>
                </>
            }
        </Content>

    );
}

export default MachinesList;