import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import axios from 'axios';
import { delete_machine_url, get_machine_url, update_machine_url } from '../../constants';

export const MachineSlice = createSlice({
    name: 'machines',
    initialState: {
        fetchServerErr: null,
        fetchMachineResult: null,
        fetchMachineError: null,
        isLoading: false,
    },
    reducers: {
        setFetchSingleMachineResult: (state, action) => {
            state.fetchMachineResult = action.payload;
            state.isLoading = false;
        },
        setFetchMachineError: (state, action) => {
            state.fetchMachineError = action.payload;
            state.isLoading = false;
            state.fetchMachineResult = null

        },
        setfetchServerErr: (state, action) => {
            state.fetchServerErr = action.payload
        },

        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        }
    }
});

export const {
    setfetchServerErr,
    setFetchSingleMachineResult,
    setFetchMachineError,
    setIsLoading,

} = MachineSlice.actions;

export const fetchMachine = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: get_machine_url,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };

    dispatch(setIsLoading(true));
    // Reset 500 error, so the user isn't stuck in that state forever.
    dispatch(setfetchServerErr(null));

    const response = axios(config)
        .then(response => {
            dispatch(setFetchSingleMachineResult(response.data));
        })
        .catch(err => {
            if (err.response) {
                dispatch(setFetchMachineError(err.response.data.error));
            }
            else {
                dispatch(setfetchServerErr('Une erreur inattendue est survenue.'))
            }
        });

    return response.data;

}

export const updateMachine = (data) => async (dispatch, getState) => {
    const config = {
        method: "post",
        url: update_machine_url,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data: data.edit,
    };

    const response = await axios(config)
        .then((response) => {
            console.log("Edit successfully ")
            console.log(response);
        })
        .catch((response) => {
            console.log("can't edit")
            message.error("Une erreur est survenue. Veuillez réessayer")
            // dispatch( setmessage({name:'error',message :response.message}))
        });
    return response;
};

export const deleteMachine = (data) => async (dispatch, getState) => {
    const config = {
        method: "post",
        url: delete_machine_url,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data: data,
    };

    const response = await axios(config)
        .then((response) => {
            message.success("Machine supprimée avec succès !");
            console.log(response);
        })
        .catch((response) => {
            
            message.error("Une erreur est survenue. Veuillez réessayer")
            // dispatch( setmessage({name:'error',message :response.message}))
        });
    return response;
};

export default MachineSlice.reducer;