import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Space, Row, Result, Button, Col, Typography, Layout } from 'antd';

import { fetchProductionLineList } from './productionLineListSlice';


import PageHeader from '../../comps/pageHeader';
import AddProductionLine from './addProductionLine';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;
const { Content } = Layout;


const ProductionLineList = () => {
    let navigate = useNavigate();

    const { useEffect } = React;
    const dispatch = useDispatch();
    let fetchServerErr = useSelector(
        (state) => state.productionLine.fetchServerErr
    );

    useEffect(() => {
        dispatch(fetchProductionLineList({}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchProductionLineListResult = useSelector(
        (state) => state.productionLine.fetchProductionLineListResult
    );

    const isFetchingProductionLineList = useSelector(
        (state) => state.productionLine.isFetchingProductionLineList
    );

    const [visible, setVisible] = useState(false)

    const columns = [
        {
            title: 'Nom de la ligne',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Ligne de l\'usine',
            dataIndex: 'plant_line',
            key: 'plant_line',
        },
        {
            title: 'identifiant de ligne',
            dataIndex: 'line_id',
            key: 'line_id',
        },
        {
            title: 'Motif des panne récurrente',
            dataIndex: 'recurring_breakdown_reason',
            key: 'recurring_breakdown_reason',
        },

        //     title: "états",
        //     dataIndex: "status",
        //     key: "status",
        //     width: "10%",

        //     render: (text) => {
        //         const colors = {
        //             active: "green",
        //             blocked: "red",
        //         };
        //         return <Badge color={colors[text]} text={text} />
        //     },
        // },

        {
            title: "Actions",
            align: "center",
            dataIndex: "_id",
            key: "_id",
            render: (_id) => {
                return (
                    <Button
                        type="link"
                        color='green'
                        // icon={<EditOutlined />}
                        size="small"
                        onClick={(e) => {
                            navigate(_id)
                        }}
                    >
                        Gérer
                    </Button>
                );
            },
        },

    ];



    return (

        <Content
            className="site-layout-background"
            style={{
                padding: 0,
                margin: 0,
                minHeight: 280,
            }}
        >
            {fetchServerErr ?
                <Result
                    status="500"
                    title="500"
                    subTitle="Une erreur inattendue est survenue, veuillez réessayer plus tard !"
                />
                : <>
                    {/* <SingleAdmin openDrawer={openDrawer} onClose={onClose} adminID={adminID} />
*/}
                    <AddProductionLine visible={visible} setVisible={setVisible} />

                    <PageHeader title={"Vue d’ensemble des lignes de production"}
                        breadcrumbItems={["Les lignes de production", "Vue d’ensemble "]} />
                    <Row justify={"center"} style={{ background: "black", margin: "24px", minHeight: "112px" }}
                    >
                        <Col span={5} style={{ paddingTop: 10 }}                >
                            <Space align='center' direction="vertical" size="middle" style={{ display: 'flex' }}>
                                <Text style={{ color: "white", fontSize: "16px" }}>Ajouter une Ligne</Text>
                                <Button type='primary'
                                    onClick={() => { setVisible(true) }}
                                    style={{ width: "132px", height: "32px" }}> Nouvelle ligne</Button>
                            </Space>
                        </Col>
                    </Row>
                    <Row justify={"center"} style={{ margin: "24px" }}>


                        {fetchProductionLineListResult && fetchProductionLineListResult.data ?
                            <Table style={{ width: "100%" }} columns={columns} pagination={{ total: fetchProductionLineListResult?.total, pageSize: 10 }}
                                loading={isFetchingProductionLineList} dataSource={fetchProductionLineListResult ? fetchProductionLineListResult.data : null} />
                            :
                            <Table style={{ width: "100%" }} columns={columns}

                                loading={isFetchingProductionLineList} dataSource={null} />
                        }
                    </Row>
                </>
            }
        </Content>

    );
}

export default ProductionLineList;