

import { Button, Card, Col, Form, Divider, Layout, Row, Switch, Typography, Input, Popconfirm, message, Modal, QRCode, Flex, Select, InputNumber } from "antd";
import { useEffect, useState } from "react";
import { CloseCircleOutlined, CheckCircleTwoTone, LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../comps/pageHeader";
import { DeleteSingleProductionLine, fetchSingleProductionLine, setDeleteSingleProductionLineResult, updateSingleProductionLine } from "./productionLineSlice";
import PrintQrComp from "../../comps/qrComp";
import { hostname } from "../../constants";
import { fetchMachineList } from "../machinesList/machinesListSlice";
import MachinesList from "../machinesList/machinesList";

const { Paragraph, Text } = Typography
const { Content } = Layout

const SingleProductionLine = ({ visible, setVisible, slectedTheme }) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [state, setstate] = useState({
        switcher: false,
        disable: false,
        editSingleAdmin: false,
    });
    const [edit, setEdit] = useState({
        id: null,
        name: "",
        plant_code: "",
        region: "",
        city: "",
        plant_line: "",
        line_id: "",
        recurring_breakdown_reason: [],
    });
    const { id } = useParams();
    const fetchSingleProductionLineResult = useSelector((state) => state.singleProductionLine.fetchSingleProductionLineResult);
    const isfetchingSingleProductionLine = useSelector((state) => state.singleProductionLine.isfetchingSingleProductionLine);

    const updateSingleProductionLineResult = useSelector((state) => state.singleProductionLine.updateSingleProductionLineResult);


    const DeleteSingleProductionLineResult = useSelector((state) => state.singleProductionLine.DeleteSingleProductionLineResult);
    const isDeletingSingleProductionLine = useSelector((state) => state.singleProductionLine.isDeletingSingleProductionLine);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {

        if (id) {
            dispatch(fetchSingleProductionLine({ id }));
            dispatch(fetchMachineList({ production_line_id: id }));
            setEdit({ ...edit, id: id })

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, updateSingleProductionLineResult]);

    useEffect(() => {
        if (DeleteSingleProductionLineResult) {
            navigate("/dashboard/production_line/list")
            dispatch(setDeleteSingleProductionLineResult(null))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [DeleteSingleProductionLineResult]);

    const handelUpdateInfo = async () => {

        dispatch(updateSingleProductionLine({ id: id, edit })).then((res) => {
            dispatch(fetchSingleProductionLine({ id }));

            setstate({
                ...state,
                switcher: false,
                disable: false,
                editSingleVariant: false,
            });
        });
        dispatch(fetchSingleProductionLine({}));

    };

    const confirm = (e) => {
        dispatch(DeleteSingleProductionLine({ id: id }))
    };
    const cancel = (e) => {
        console.log(e);
        message.error('Ligne non supprimée');
    };
    const handelSelectRegion = (value, name) => {
        setEdit({ ...edit, region: value });
    };
    const handelSelectCity = (value, name) => {
        setEdit({ ...edit, city: value });
    };
    const handelSelectPlantCode = (value, name) => {
        setEdit({ ...edit, plant_code: value });
    };
    return (
        <>
            <PageHeader title={"Informations détaillées sur La ligne “ " + fetchSingleProductionLineResult?.data.name + " ”"}
                breadcrumbItems={["Panneau Utilisateurs", "Informations Détaillées"]} />
            <Modal onCancel={handleCancel} closable open={isModalOpen} footer={null}>
                {!isfetchingSingleProductionLine && fetchSingleProductionLineResult ? (
                    <PrintQrComp link={"production_line/" + id} name={fetchSingleProductionLineResult.data?.name} />) : null}
            </Modal>
            <Row justify={"space-between"} style={{ margin: "24px" }}>
                <Col span={9}>
                    <Card
                        headStyle={{ backgroundColor: "#ff000f" }}
                        hoverable
                        style={{ minHeight: "70vh", cursor: "auto" }}
                        loading={isfetchingSingleProductionLine || isDeletingSingleProductionLine}
                        extra={
                            <Switch
                                checkedChildren="Modifier"
                                unCheckedChildren="Modifier"
                                disabled={state.disable}
                                checked={state.switcher}
                                onChange={(e) => {
                                    setstate({
                                        ...state,
                                        switcher: true,
                                        disable: true,
                                        editSingleAdmin: true,
                                    });
                                    setEdit({
                                        ...edit,
                                        name: fetchSingleProductionLineResult?.data.name,
                                        installation_date: fetchSingleProductionLineResult?.data.installation_date,
                                        recurring_breakdown_reason: fetchSingleProductionLineResult?.data.recurring_breakdown_reason,
                                        plant_code: fetchSingleProductionLineResult?.data.plant_code,
                                        region: fetchSingleProductionLineResult?.data.region,
                                        city: fetchSingleProductionLineResult?.data.city,
                                        plant_line: fetchSingleProductionLineResult?.data.plant_line,
                                        line_id: fetchSingleProductionLineResult?.data.line_id,
                                    });
                                }}
                            />
                        }
                        actions={[
                            <Popconfirm
                                title="Supprimer la ligne"
                                description="Voulez-vous vraiment supprimer cette ligne?"
                                onConfirm={confirm}
                                onCancel={cancel}
                                okText="Oui"
                                cancelText="Non"
                            >
                                <Button type="dashed" style={{ width: "85%" }} disabled={state.switcher}>
                                    Supprimer
                                </Button>
                            </Popconfirm>
                        ]}
                    >
                        <Row gutter={[16, 16]} direction="vertical" align="center" style={{ width: "100%" }}>
                            <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
                                {!isfetchingSingleProductionLine && fetchSingleProductionLineResult ? (
                                    <QRCode
                                        value={hostname + "production_line/" + id}
                                        name={fetchSingleProductionLineResult.data?.name}
                                        size={200}
                                    />
                                ) : null}
                            </Col>
                            <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
                                <Button type="dashed" onClick={showModal}>
                                    Aperçu et impression
                                </Button>
                            </Col>
                        </Row>
                        <Row gutter={[25, 8]} align="middle">
                            <Col xs={22} sm={22} md={22} lg={22} xl={22} xxl={22} style={{
                                background: "white", height: "100%", minHeight: "30vh", padding: 10
                            }}>
                                {!isfetchingSingleProductionLine && fetchSingleProductionLineResult ? (
                                    <>
                                        <Content>
                                            <div>
                                                <Form layout="horizontal">
                                                    <Divider orientation="left" style={{ borderBlockColor: "#CCD1D1" }}>
                                                        <Text mark>Code de l'usine | La region | La ville</Text>
                                                    </Divider>
                                                    <Form.Item style={{ width: "100%", justifyContent: "center" }}>
                                                        {state.switcher ?
                                                            <Flex justify="flex-end" gap={10}>
                                                                <Select
                                                                    placeholder="Code de l'usine"
                                                                    value={edit.plant_code}
                                                                    options={[{ value: 'W145', label: 'W145' }]}
                                                                    onSelect={handelSelectPlantCode}
                                                                />
                                                                <Select
                                                                    placeholder="La region"
                                                                    value={edit.region}
                                                                    name="region"
                                                                    key={"region"}
                                                                    options={[{ value: 'MEA/TR', label: 'MEA/TR' }]}
                                                                    onSelect={handelSelectRegion}
                                                                />
                                                                <Select
                                                                    placeholder="La ville"
                                                                    value={edit.city}
                                                                    options={[{ value: 'Reghaia', label: 'Reghaia' }]}
                                                                    onSelect={handelSelectCity}
                                                                />
                                                            </Flex>
                                                            :
                                                            <>
                                                                <Text strong style={{ fontSize: "15px", marginLeft: "20px" }}>
                                                                    {fetchSingleProductionLineResult.data?.plant_code}
                                                                </Text>
                                                                <Divider type="vertical" style={{ borderBlockColor: "#CCD1D1" }} />
                                                                <Text strong style={{ fontSize: "15px", marginLeft: "20px" }}>
                                                                    {fetchSingleProductionLineResult.data?.region}
                                                                </Text>
                                                                <Divider type="vertical" style={{ borderBlockColor: "#CCD1D1" }} />
                                                                <Text strong style={{ fontSize: "15px", marginLeft: "20px" }}>
                                                                    {fetchSingleProductionLineResult.data?.city}
                                                                </Text>
                                                            </>
                                                        }
                                                    </Form.Item>
                                                    <Divider orientation="left" style={{ borderBlockColor: "#CCD1D1" }}>
                                                        <Text mark>Le nom de la ligne</Text>
                                                    </Divider>
                                                    <Form.Item style={{ width: "100%", justifyContent: "center" }}>
                                                        {state.switcher ?
                                                            <Input
                                                                style={{ marginLeft: "20px" }}
                                                                value={edit.name}
                                                                onChange={(e) => setEdit({ ...edit, name: e.target.value })}
                                                            /> :
                                                            <Text strong style={{ fontSize: "15px", marginLeft: "20px" }}>
                                                                {fetchSingleProductionLineResult.data?.name}
                                                            </Text>}
                                                    </Form.Item>
                                                    <Divider orientation="left" style={{ borderBlockColor: "#CCD1D1" }}>
                                                        <Text mark>Ligne de l'usine</Text>
                                                    </Divider>
                                                    <Form.Item style={{ width: "100%", justifyContent: "center" }}>
                                                        {state.switcher ?
                                                            <Input
                                                                style={{ marginLeft: "20px" }}
                                                                value={edit.plant_line}
                                                                onChange={(e) => setEdit({ ...edit, plant_line: e.target.value })}
                                                            /> :
                                                            <Text strong style={{ fontSize: "15px", marginLeft: "20px" }}>
                                                                {fetchSingleProductionLineResult.data?.plant_line}
                                                            </Text>}
                                                    </Form.Item>
                                                    <Divider orientation="left" style={{ borderBlockColor: "#CCD1D1" }}>
                                                        <Text mark>Identifiant de ligne</Text>
                                                    </Divider>
                                                    <Form.Item style={{ width: "100%", justifyContent: "center" }}>
                                                        {state.switcher ?
                                                            <InputNumber
                                                                min={0}
                                                                style={{ marginLeft: "20px" }}
                                                                value={edit.line_id}
                                                                onChange={(value) => setEdit({ ...edit, line_id: value })}
                                                            /> :
                                                            <Text strong style={{ fontSize: "15px", marginLeft: "20px" }}>
                                                                {fetchSingleProductionLineResult.data?.line_id}
                                                            </Text>}
                                                    </Form.Item>

                                                    <Divider orientation="left" style={{ borderBlockColor: "#CCD1D1" }}>
                                                        <Text mark>Motif de panne récurrente</Text>
                                                    </Divider>
                                                    <Form.Item style={{ width: "100%", justifyContent: "center" }}>
                                                        {state.switcher ?
                                                            <Input.TextArea
                                                                rows={4}
                                                                style={{ marginLeft: "20px" }}
                                                                value={edit.recurring_breakdown_reason}
                                                                onChange={(event) => {
                                                                    setEdit({ ...edit, recurring_breakdown_reason: event.target.value ? [event.target.value] : [] });
                                                                }} /> :
                                                            <Paragraph strong style={{ fontSize: "15px", marginLeft: "20px", whiteSpace: "pre-line" }}>
                                                                {fetchSingleProductionLineResult.data?.recurring_breakdown_reason}
                                                            </Paragraph >}
                                                    </Form.Item>
                                                </Form>
                                                {state.disable && (
                                                    <Divider>
                                                        <div style={{ display: "flex", justifyContent: "center", marginTop: 33 }}>
                                                            <Button type="primary" style={{ marginRight: "10px", backgroundColor: "#4CAF50" }} onClick={handelUpdateInfo}>
                                                                Mettre à jour <CheckCircleTwoTone twoToneColor="#52c41a" />
                                                            </Button>
                                                            <Button
                                                                type="primary"
                                                                style={{ marginLeft: "10px" }}
                                                                danger
                                                                onClick={() => {
                                                                    setEdit({ ...edit });
                                                                    setstate({ ...state, switcher: false, disable: false, editSingleAdmin: false });
                                                                }}
                                                            >
                                                                Annuler <CloseCircleOutlined twoToneColor="#eb2f96" />
                                                            </Button>
                                                        </div>
                                                    </Divider>
                                                )}
                                            </div>
                                        </Content>
                                    </>
                                ) : (
                                    <div style={{ height: "80vh" }}>
                                        <LoadingOutlined
                                            twoToneColor="#eb2f96"
                                            style={{ fontSize: 24, marginLeft: "50%", marginTop: "50%" }}
                                            spin
                                        />
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={14}>
                    <MachinesList />
                </Col>
            </Row>

        </>
    );
}

export default SingleProductionLine;