

import { Col, Flex, Form, Input, InputNumber, Modal, Row, Select } from "antd";
import { useState } from "react";
// import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useDispatch } from "react-redux";
// import { fetchSelectCategories } from "../categories/categoriesSlice";
import { AddnewProductionLine } from "./productionLineListSlice";

const { TextArea } = Input;




const AddProductionLine = ({ visible, setVisible }) => {
    const modalStyles = {
        header: {
            borderLeft: `5px solid #ff000f`,
            borderRadius: 0,
            paddingInlineStart: 5,
        },
        body: {
            boxShadow: 'inset 0 0 5px #999',
            borderRadius: 5,
        },
        mask: {
            backdropFilter: 'blur(10px)',
        },

        content: {
            boxShadow: '0 0 30px #999',
        },
    };

    const dispatch = useDispatch();
    const [formData, setFormData] = useState({ name: "", plant_code: "", recurring_breakdown_reason: [], region: "", city: "", plant_line: "", line_id: "", });

    const handleInputChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleFormSubmit = (event) => {
        setVisible(false)
        dispatch(AddnewProductionLine(formData));
        setFormData({ name: "", plant_code: "", recurring_breakdown_reason: [], region: "", city: "", plant_line: "", line_id: "" })
    };
    const handelSelectRegion = (value, name) => {
        setFormData({ ...formData, region: value });
    };
    const handelSelectCity = (value, name) => {
        setFormData({ ...formData, city: value });
    };
    const handelSelectPlantCode = (value, name) => {
        setFormData({ ...formData, plant_code: value });
    };

    return (
        < >
            <Modal title="Ajouter une ligne de production"
                open={visible}
                okText="Confirmer"
                centered
                styles={modalStyles}

                maskClosable={false}
                closable={false}
                onOk={handleFormSubmit}
                okButtonProps={{ disabled: (formData.name === "" || formData.plant_code === "" || formData.region === "" || formData.city === "" || formData.plant_line === "" || formData.line_id === "") }}
                onCancel={() =>
                    setVisible(false)}
                width={600}
                mask={{
                    backdropFilter: 'blur(10px)',
                }}>
                <Row justify="center" style={{ marginTop: "20px", paddingTop: "20px" }}>


                    <Col span={20} >
                        <Form
                            layout="vertical"

                            style={{ display: "block", margin: "auto" }} >
                            <Form.Item
                                name="name"
                                label=" Le nom de la ligne"
                                rules={[
                                    { required: true, message: "Veuillez saisir le nom de la ligne" },
                                ]}
                            >
                                <Input
                                    // style={{ width: 360 }}
                                    value={formData.name}

                                    allowClear
                                    name="name"
                                    variant="filled"
                                    placeholder="Nom de la ligne"
                                    onChange={handleInputChange}
                                />
                            </Form.Item>
                            <Flex justify="space-between">

                                <Form.Item
                                    name="plant_line"
                                    label=" Ligne de l'usine"
                                    rules={[
                                        { required: true, message: "Veuillez saisir la ligne de l'usine" },
                                    ]}
                                >
                                    <Input
                                        // style={{ width: 360 }}
                                        value={formData.plant_line}

                                        allowClear
                                        name="plant_line"
                                        variant="filled"
                                        placeholder="Ligne de l'usine"
                                        onChange={handleInputChange}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="line_id"
                                    label="Identifiant de ligne"
                                    rules={[
                                        { required: true, message: "Veuillez saisir l'identifiant de ligne" },
                                    ]}
                                >
                                    <InputNumber
                                        min={0}
                                        defaultValue={0}
                                        value={formData.line_id}
                                        allowClear
                                        name="line_id"
                                        variant="filled"
                                        placeholder="Identifiant de ligne"
                                        onChange={(value) => {
                                            setFormData({ ...formData, line_id: value });

                                        }}
                                    />
                                </Form.Item>
                            </Flex>

                            <Flex justify="space-between">

                                <Form.Item
                                    style={{ width: "30%" }}
                                    name="PlantCode"
                                    label="Code de l'usine"
                                    rules={[
                                        { required: true, message: "Veuillez saisir le code de l'usine" },
                                    ]}
                                >
                                    <Select
                                        options={[
                                            {
                                                value: 'W145',
                                                label: 'W145',
                                            },

                                        ]}
                                        onSelect={handelSelectPlantCode}
                                    />
                                </Form.Item>
                                <Form.Item

                                    name="region"
                                    label="La region"
                                    rules={[
                                        { required: true, message: "Veuillez saisir le nom de la ligne" },
                                    ]}
                                    style={{ width: "30%" }}

                                >
                                    <Select
                                        name="region"
                                        key={"region"}

                                        options={[
                                            {
                                                value: 'MEA/TR',
                                                label: 'MEA/TR',
                                            },

                                        ]}
                                        onSelect={handelSelectRegion}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="city"
                                    label="La ville"
                                    rules={[
                                        { required: true, message: "Veuillez saisir la ville" },
                                    ]}
                                    style={{ width: "30%" }}

                                >
                                    <Select
                                        options={[
                                            {
                                                value: 'Reghaia',
                                                label: 'Reghaia',
                                            },

                                        ]}

                                        onSelect={handelSelectCity}
                                    />
                                </Form.Item>
                            </Flex>

                            <Form.Item
                                name="recurring_breakdown_reason"
                                label="Motif des pannes récurrentes"
                            >
                                <TextArea
                                    allowClear
                                    rows={4}
                                    name="recurring_breakdown_reason"
                                    variant="filled"
                                    placeholder="Motif de panne récurrente"
                                    onChange={(event) => {
                                        setFormData({ ...formData, recurring_breakdown_reason: event.target.value ? [event.target.value] : [] });
                                    }}
                                />
                            </Form.Item>


                        </Form>
                    </Col>


                </Row>
            </Modal>

        </>
    );
}

export default AddProductionLine;