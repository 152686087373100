import React, { useEffect, useState } from 'react';
import { Layout, Menu, ConfigProvider, Button, Flex } from 'antd';
import {
    PoweroffOutlined,
} from '@ant-design/icons';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setPathname } from './dashboardSlice';

import frFR from 'antd/locale/fr_FR';
// import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import './dashboard.css'

import { jwtDecode } from "jwt-decode";
import { Helmet } from 'react-helmet';
import { setredirectToDashboard } from '../sigIn/signInSlice';
// import axios from 'axios';

// const { Text } = Typography;

const { Header, Content, Footer } = Layout;

const Dashboard = () => {
    const dispatch = useDispatch();
    let location = useLocation();
    let navigate = useNavigate();

    // eslint-disable-next-line no-unused-vars
    const [helmet, setHelmet] = useState("Ligne de production");

    const pathname = useSelector((state) => state.dashboard.pathname);
    // const fetchProfileResult = useSelector((state) => state.dashboard.fetchProfileResult);

    const boxStyle = {
        width: '100%',

    };
    useEffect(() => {
        dispatch(setPathname(location.pathname));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            if (decodedToken.exp < currentTime) {
                localStorage.removeItem("token");
                navigate("/signin")

                // Token has expired
            }
        } else {
            navigate("/signin")

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    // useEffect(() => {
    //     if (pathname !== "") {
    //         const pathElements = pathname.split('/').filter(Boolean).pop();;
    //         setHelmet(
    //             pathElements
    //         );
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [pathname]);

    return (
        <ConfigProvider
            locale={frFR}
            theme={{
                token: {
                    colorPrimary: "#ff000f",
                    borderRadius: "2px",
                    fontFamily: "Roboto"
                },
            }}
        >
            <Layout
                style={{ minHeight: "100vh" }}
            >
                <Header
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Flex style={boxStyle} justify={"space-between"} align={"center"}>
                        <div style={{ marginRight: "48px" }} >
                            <img
                                src="/logoWhite.svg"
                                alt="logo"
                                style={{ height: "80px", width: "80px", display: "block" }}
                            />
                        </div>
                        <Menu
                            theme="dark"
                            mode="horizontal"
                            defaultSelectedKeys={['PL']}
                            style={{
                                flex: 1,
                                minWidth: 0,
                            }}

                        >
                            <Menu.Item key={"PL"}>
                                <NavLink to={`/dashboard/production_line/list`}>Ligne de production</NavLink>

                            </Menu.Item>
                        </Menu>
                        <Button shape='round' icon={<PoweroffOutlined />}
                            onClick={() => {
                                localStorage.removeItem("token");
                                navigate("/signin")
                                dispatch(setredirectToDashboard())
                            }}
                        >
                            Se déconnecter !
                        </Button>
                    </Flex>

                </Header>
                <Content
                    className="site-layout-background"

                    style={{
                        margin: 0,
                        minHeight: 280,
                    }}
                >
                    <div
                        style={{ margin: '16px 0', marginLeft: "16px", minHeight: 280, padding: 24, borderRadius: 3,}}
                    >
                        <>
                            <Helmet>
                                <meta charSet="utf-8" />
                                <title>{helmet}</title>
                            </Helmet>

                            <Outlet />
                        </>
                    </div>
                </Content>
                <Footer
                    style={{
                        textAlign: 'center',
                    }}
                >
                    Henkel ©{new Date().getFullYear()} Created by Nebultech
                </Footer>
            </Layout>
        </ConfigProvider >
    );
};

export default Dashboard;
