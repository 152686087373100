export const hostname = "https://henkel-dev.nebultech.com/"

const base_url = `${process.env.REACT_APP_HOSTNAME || "http://localhost:3006"}/api/dashboard`;


export const login_url = base_url + "/admin/login";
export const admins_url = base_url + "/admin/getAdminsList";
export const profile_url = base_url + "/admin/getAdminProfile";
export const production_line_list_url = base_url + "/productionLine/getProductionLinesList";
export const createProductionLine_url = base_url + "/productionLine/createProductionLine";
export const getProductionLineById_url = base_url + "/productionLine/getProductionLineById";
export const updateProductionLine_url = base_url + "/productionLine/updateProductionLine";
export const deleteProductionLine_url = base_url + "/productionLine/deleteProductionLine";
export const createMachine_url = base_url + "/machine/createMachine";
export const machines_list_url = base_url + "/machine/getMachinesList";
export const get_machine_url = base_url + "/machine/getMachineById"
export const update_machine_url = base_url + "/machine/updateMachine"
export const delete_machine_url = base_url + "/machine/deleteMachine"

