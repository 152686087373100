

import { Col, DatePicker, Flex, Form, Input, InputNumber, Modal, Row } from "antd";
import { useEffect, useState } from "react";
// import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
// import { fetchSelectCategories } from "../categories/categoriesSlice";
import { AddnewMachine, fetchMachineList } from "./machinesListSlice";
import moment from "moment";
import dayjs from 'dayjs';
import { useParams } from "react-router-dom";

const { TextArea } = Input;




const AddMachine = ({ visible, setVisible }) => {
    const modalStyles = {
        header: {
            borderLeft: `5px solid #ff000f`,
            borderRadius: 0,
            paddingInlineStart: 5,
        },
        body: {
            boxShadow: 'inset 0 0 5px #999',
            borderRadius: 5,
        },
        mask: {
            backdropFilter: 'blur(10px)',
        },

        content: {
            boxShadow: '0 0 30px #999',
        },
    };
    const { id } = useParams();

    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        name: "", construction_date: "", equipment_descriptor: "", equipment_local: "", manufacturer: "", model: "", serial_number: "", asset_number: "",
        functional_location: "", operating_location: "", psv_1: "", speed_1: "", psv_2: "", speed_2: "",
        recurring_breakdown_reason_history: [], production_line: "", equipment_id: 0,
    });

    const addnewMachineresult = useSelector(
        (state) => state.machines.addnewMachineresult
    );

    const handleInputChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleFormSubmit = (event) => {
        console.log("formData", formData);
        setVisible(false)
        dispatch(AddnewMachine(formData));
        setFormData({
            ...formData, name: "", construction_date: "", equipment_descriptor: "", equipment_local: "", manufacturer: "", model: "", serial_number: "", asset_number: "",
            functional_location: "", operating_location: "", psv_1: "", speed_1: "", psv_2: "", speed_2: "",
            recurring_breakdown_reason_history: [], production_line: "", equipment_id: 0,
        })
    };

    useEffect(() => {

        if (id) {

            setFormData({ ...formData, production_line: id })

        }
        // dispatch(getBannerInfo());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    useEffect(() => {

        if (addnewMachineresult) {

            dispatch(fetchMachineList({ production_line_id: id }));

        }
        // dispatch(getBannerInfo());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addnewMachineresult]);


    return (
        < >
            <Modal
                title="Ajouter une machine"
                open={visible}
                okText="Confirmer"
                centered
                styles={modalStyles}

                maskClosable={false}
                // closable={false}
                onOk={handleFormSubmit}
                okButtonProps={{
                    disabled: (
                        formData.name === "" ||
                        formData.equipment_descriptor === "" ||
                        formData.construction_date === "" ||
                        formData.equipment_local === "" ||
                        formData.manufacturer === "" ||
                        formData.model === "" ||
                        formData.serial_number === "" ||
                        formData.asset_number === "" ||
                        formData.functional_location === "" ||
                        formData.operating_location === "" ||
                        formData.psv_1 === "" ||
                        formData.speed_1 === "" ||
                        formData.psv_2 === "" ||
                        formData.speed_2 === "" ||
                        formData.equipment_id === 0
                    )
                }}
                onCancel={() => setVisible(false)}
                width={900}
                mask={{ backdropFilter: 'blur(10px)' }}
            >
                <Row justify="center" style={{ marginTop: "20px", paddingTop: "20px" }}>
                    <Col span={20}>
                        <Form layout="vertical" style={{ display: "block", margin: "auto" }}>
                            <Flex justify="space-between" >
                                <Form.Item
                                    style={{ width: "47%" }}
                                    name="name"
                                    label="Le nom"
                                    rules={[{ required: true, message: "Veuillez saisir le nom" }]}
                                >
                                    <Input
                                        value={formData.name}
                                        allowClear
                                        name="name"
                                        variant="filled"
                                        placeholder="Nom"
                                        onChange={handleInputChange}
                                    />
                                </Form.Item>

                                <Form.Item
                                    style={{ width: "47%" }}
                                    name="equipment_id"
                                    label="Identifiant"
                                    rules={[{ required: true, message: "Veuillez saisir l'identifiant de l'équipement" }]}

                                >
                                    <InputNumber
                                        min={0}
                                        defaultValue={0}
                                        value={formData.equipment_id}
                                        allowClear
                                        name="equipment_id"
                                        variant="filled"
                                        placeholder="Identifiant de l'équipement"
                                        onChange={(value) => {
                                            setFormData({ ...formData, equipment_id: value });

                                        }}
                                    />
                                </Form.Item>
                            </Flex>
                            <Flex justify="space-between">

                                <Form.Item
                                    style={{ width: "47%" }}

                                    name="equipment_descriptor"
                                    label="La description"
                                    rules={[{ required: true, message: "Veuillez saisir la description" }]}
                                >
                                    <Input
                                        value={formData.equipment_descriptor}
                                        allowClear
                                        name="equipment_descriptor"
                                        variant="filled"
                                        placeholder="Description"
                                        onChange={handleInputChange}
                                    />
                                </Form.Item>

                                <Form.Item
                                    style={{ width: "47%" }}

                                    name="equipment_local"
                                    label="Le nom local"
                                    rules={[{ required: true, message: "Veuillez saisir le nom local" }]}
                                >
                                    <Input
                                        value={formData.equipment_local}
                                        allowClear
                                        name="equipment_local"
                                        variant="filled"
                                        placeholder="Le nom local"
                                        onChange={handleInputChange}
                                    />
                                </Form.Item>
                            </Flex>
                            <Flex justify="space-between">

                                <Form.Item
                                    style={{ width: "47%" }}

                                    name="manufacturer"
                                    label="Fabricant"
                                    rules={[{ required: true, message: "Veuillez saisir le fabricant" }]}
                                >
                                    <Input
                                        value={formData.manufacturer}
                                        allowClear
                                        name="manufacturer"
                                        variant="filled"
                                        placeholder="Fabricant"
                                        onChange={handleInputChange}
                                    />
                                </Form.Item>

                                <Form.Item
                                    style={{ width: "47%" }}
                                    name="model"
                                    label="Modèle"
                                    rules={[{ required: true, message: "Veuillez saisirle modèle" }]}
                                >
                                    <Input
                                        value={formData.model}
                                        allowClear
                                        name="model"
                                        variant="filled"
                                        placeholder="Modèle"
                                        onChange={handleInputChange}
                                    />
                                </Form.Item>
                            </Flex>
                            <Flex justify="space-between">

                                <Form.Item
                                    style={{ width: "47%" }}
                                    name="serial_number"
                                    label="Numéro de série"
                                    rules={[{ required: true, message: "Veuillez saisir le numéro de série" }]}
                                >
                                    <Input
                                        value={formData.serial_number}
                                        allowClear
                                        name="serial_number"
                                        variant="filled"
                                        placeholder="Numéro de série"
                                        onChange={handleInputChange}
                                    />
                                </Form.Item>
                                <Form.Item
                                    style={{ width: "47%" }}
                                    name="asset_number"
                                    label="Numéro d'actif"
                                    rules={[{ required: true, message: "Veuillez saisir le numéro d'actif" }]}
                                >
                                    <Input
                                        value={formData.asset_number}
                                        allowClear
                                        name="asset_number"
                                        variant="filled"
                                        placeholder="Numéro d'actif"
                                        onChange={handleInputChange}
                                    />
                                </Form.Item>
                            </Flex>
                            <Flex justify="space-between">

                                <Form.Item
                                    style={{ width: "47%" }}
                                    name="functional_location"
                                    label="Localisation fonctionnelle"
                                    rules={[{ required: true, message: "Veuillez saisir la localisation fonctionnelle" }]}
                                >
                                    <Input
                                        value={formData.functional_location}
                                        allowClear
                                        name="functional_location"
                                        variant="filled"
                                        placeholder="Localisation fonctionnelle"
                                        onChange={handleInputChange}
                                    />
                                </Form.Item>
                                <Form.Item
                                    style={{ width: "47%" }}
                                    name="operating_location"
                                    label="Emplacement d'exploitation"
                                    rules={[{ required: true, message: "Veuillez saisir l'emplacement d'exploitation" }]}
                                >
                                    <Input
                                        value={formData.operating_location}
                                        allowClear
                                        name="operating_location"
                                        variant="filled"
                                        placeholder="Emplacement d'exploitation"
                                        onChange={handleInputChange}
                                    />
                                </Form.Item>
                            </Flex>

                            <Flex justify="space-between">

                                <Form.Item
                                    style={{ width: "47%" }}
                                    name="psv_1"
                                    label="psv_1"
                                    rules={[{ required: true, message: "Veuillez saisir psv_1" }]}
                                >
                                    <Input
                                        value={formData.psv_1}
                                        allowClear
                                        name="psv_1"
                                        variant="filled"
                                        placeholder="psv_1"
                                        onChange={handleInputChange}
                                    />
                                </Form.Item>
                                <Form.Item
                                    style={{ width: "47%" }}
                                    name="speed_1"
                                    label="Speed1 [CON/min]"
                                    rules={[{ required: true, message: "Veuillez saisir speed 2 " }]}
                                >
                                    <Input
                                        value={formData.speed_1}
                                        allowClear
                                        name="speed_1"
                                        variant="filled"
                                        placeholder="Speed1 [CON/min]"
                                        onChange={handleInputChange}
                                    />
                                </Form.Item>
                            </Flex>
                            <Flex justify="space-between">

                                <Form.Item
                                    style={{ width: "47%" }}
                                    name="psv_2"
                                    label="psv_2"
                                    rules={[{ required: true, message: "Veuillez saisir psv_2" }]}
                                >
                                    <Input
                                        value={formData.psv_2}
                                        allowClear
                                        name="psv_2"
                                        variant="filled"
                                        placeholder="psv_2"
                                        onChange={handleInputChange}
                                    />
                                </Form.Item>
                                <Form.Item
                                    style={{ width: "47%" }}
                                    name="speed_2"
                                    label="Speed 2 [CON/min]"
                                    rules={[{ required: true, message: "Veuillez saisir speed 2 " }]}
                                >
                                    <Input
                                        value={formData.speed_2}
                                        allowClear
                                        name="speed_2"
                                        variant="filled"
                                        placeholder="Speed 2 [CON/min]"
                                        onChange={handleInputChange}
                                    />
                                </Form.Item>
                            </Flex>
                            <Flex justify="space-between">
                                <Form.Item
                                    style={{ width: "65%" }}

                                    name="recurring_breakdown_reason_history"
                                    label="Historique des motifs de pannes récurrentes"
                                >
                                    <TextArea
                                        allowClear
                                        rows={3}
                                        name="recurring_breakdown_reason_history"
                                        variant="filled"
                                        placeholder="Historique des motifs de pannes récurrentes"
                                        onChange={(event) => {
                                            setFormData({ ...formData, recurring_breakdown_reason_history: event.target.value ? [event.target.value] : [] });
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    style={{ width: "25%" }}

                                    label="Année de construction"
                                    name="construction_date">
                                    <DatePicker
                                        allowClear
                                        presets={[
                                            { label: 'Hier', value: dayjs().add(-1, 'd') },
                                            { label: 'La semaine dernière', value: dayjs().add(-7, 'd') },
                                            { label: 'Le mois dernier', value: dayjs().add(-1, 'month') },
                                        ]}
                                        onChange={(value, dateString) => {
                                            setFormData({
                                                ...formData,
                                                construction_date: dateString ? moment(dateString).format() : "",
                                            });
                                        }}
                                    />
                                </Form.Item>


                            </Flex>


                        </Form>
                    </Col>
                </Row>
            </Modal>


        </>
    );
}

export default AddMachine;