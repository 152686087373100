import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import axios from 'axios';
import { deleteProductionLine_url, getProductionLineById_url, updateProductionLine_url } from '../../constants';
export const ProductionLineSlice = createSlice({
    name: 'productionLine',
    initialState: {

        fetchSingleProductionLineResult: null,
        fetchSingleProductionLineError: null,
        isfetchingSingleProductionLine: false,

        DeleteSingleProductionLineResult: null,
        isDeletingSingleProductionLine: false,

    },
    reducers: {
        setSingleProductionLineResult: (state, action) => {
            state.fetchSingleProductionLineResult = action.payload;
            state.fetchSingleProductionLineError = null;
            state.isfetchingSingleProductionLine = false;
        },

        setSingleProductionLineError: (state, action) => {
            state.fetchSingleProductionLineError = action.payload;
            state.fetchSingleProductionLineResult = null;
            state.isfetchingSingleProductionLine = false;
        },
        setisfetchingSingleProductionLine: (state, action) => {
            state.isfetchingSingleProductionLine = action.payload;
        },

        setDeleteSingleProductionLineResult: (state, action) => {
            state.DeleteSingleProductionLineResult = action.payload;
            state.isDeletingSingleProductionLine = false;
        },
        setisDeletingSingleProductionLine: (state, action) => {
            state.isDeletingSingleProductionLine = action.payload;
        },

    }
});

export const {

    setSingleProductionLineResult,
    setSingleProductionLineError,
    setisfetchingSingleProductionLine,

    setDeleteSingleProductionLineResult,
    setisDeletingSingleProductionLine

} = ProductionLineSlice.actions;

export const fetchSingleProductionLine = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: getProductionLineById_url,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data,
    };
    dispatch(setisfetchingSingleProductionLine(true));

    const response = axios(config)
        .then((response) => {
            dispatch(setSingleProductionLineResult(response.data));
            // dispatch( setmessage({name:'success',message :"SingleProductionLine fetched successfully"}))
            console.log(response);
        })
        .catch((err) => {
            dispatch(setSingleProductionLineError(err.response.data.message));
        });

    console.log(response);
    return response.data;
};
export const updateSingleProductionLine = (data) => async (dispatch, getState) => {
    const config = {
        method: "post",
        url: updateProductionLine_url,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data: data.edit,
    };

    const response = await axios(config)
        .then((response) => {
            message.success(response.data.message)
            console.log(response);
        })
        .catch((response) => {
            console.log("can't edit")
            message.error(response.response.data.message)

            // dispatch( setmessage({name:'error',message :response.message}))
        });
    return response;
};

export const DeleteSingleProductionLine = (data) => async (dispatch, getState) => {

    const config = {
        method: "post",
        url: deleteProductionLine_url,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data: data,
    };
    dispatch(setisfetchingSingleProductionLine(true));

    const response = await axios(config)
        .then((response) => {
            message.success(response.data.message)
            dispatch(setDeleteSingleProductionLineResult(response.data.message))
        })
        .catch((response) => {
            if (response.response) {

                message.error(response.response.data.message)
            }

            // dispatch( setmessage({name:'error',message :response.message}))
        });
    return response;
};


export default ProductionLineSlice.reducer;