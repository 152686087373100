import { Row, Col, Typography, QRCode, Button, Divider } from 'antd';
import { useReactToPrint } from 'react-to-print';
import { hostname } from "../constants";
import { useRef } from 'react';
import "../features/dashboard/dashboard.css"
import {
    PrinterOutlined
} from '@ant-design/icons';

const { Title } = Typography;

const PrintQrComp = ({ link, name }) => {
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: true,
        documentTitle: name,
        // print: true
    });

    let componentRef = useRef();

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Row>
                <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                    <div ref={componentRef} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                        <div style={{ border: "dashed ", width: "300px", borderColor: 'gray', borderWidth: "2px" }}>
                            <QRCode value={hostname + link} size={300} />
                        </div>
                        <Title level={4} style={{ textAlign: "center" }}>{name}</Title>
                    </div>
                </Col>
            </Row>
            <Divider></Divider>
            <Row>
                <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button icon={<PrinterOutlined />} type='primary' onClick={handlePrint}>Imprimer</Button>
                </Col>
            </Row>
        </div>
    );
}

export default PrintQrComp;
