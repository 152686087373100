import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import axios from 'axios';
import { createMachine_url, machines_list_url } from '../../constants';


export const MachineSlice = createSlice({
    name: 'machines',
    initialState: {

        fetchServerErr: null,
        fetchMachineListResult: null,
        fetchMachineListError: null,
        isFetchingMachineList: false,

        addnewMachineresult: null,
        addnewMachineError: null,
        isAddingNewMachineresult: false,

    },
    reducers: {
        setFetchMachineResult: (state, action) => {
            state.fetchMachineListResult = action.payload;
            state.isFetchingMachineList = false;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setFetchMachineError: (state, action) => {
            state.fetchMachineListError = action.payload;
            state.isFetchingMachineList = false;
            state.fetchMachineListResult = null

        },
        setfetchServerErr: (state, action) => {
            state.fetchServerErr = action.payload
        },

        setIsFetchingMachine: (state, action) => {
            state.isFetchingMachineList = action.payload;
        },

        setAddnewMachineresult: (state, action) => {
            state.addnewMachineresult = action.payload;
            state.addnewMachineError = null;
            state.isAddingNewMachineresult = false;
        },

        setAddnewMachineError: (state, action) => {
            state.addnewMachineError = action.payload;
            state.addnewMachineresult = null;
            state.isAddingNewMachineresult = false;
        },
        setIAddingMachine: (state, action) => {
            state.isAddingNewMachineresult = action.payload;
        },
    }
});

export const {

    setfetchServerErr,

    setFetchMachineResult,
    setFetchMachineError,
    setConnected,
    setIsFetchingMachine,

    setAddnewMachineresult,
    setAddnewMachineError,
    setIAddingMachine

} = MachineSlice.actions;

export const fetchMachineList = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: machines_list_url,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data

    };
    dispatch(setIsFetchingMachine(true));
    const response = axios(config)
        .then(response => {
            dispatch(setFetchMachineResult(response.data));
        })
        .catch(err => {
            if (err.respons) {
                dispatch(setFetchMachineError(err.response.data.error));
            }
            else {
                dispatch(setfetchServerErr('Err'))
            }
        });

    return response.data;

}
export const AddnewMachine = (data) => async (dispatch, getState) => {
    const config = {
        method: "post",
        url: createMachine_url,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data,
    };
    dispatch(setIAddingMachine(true));

    const response = await axios(config)
        .then((response) => {
            message.success(response.data.message)
            dispatch(setAddnewMachineresult(response.data.message));
        })
        .catch((error) => {
            message.error(error.response.data.message)
            dispatch(setAddnewMachineError(error.response.data.message));
            
        });
    console.log(response);
    return response;
};



export default MachineSlice.reducer;