import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import axios from 'axios';
import {  createProductionLine_url, production_line_list_url } from '../../constants';


export const ProductionLineSlice = createSlice({
    name: 'productionLine',
    initialState: {

        fetchServerErr: null,
        fetchProductionLineListResult: null,
        fetchProductionLineListError: null,
        isFetchingProductionLineList: false,

    },
    reducers: {
        setFetchProductionLineResult: (state, action) => {
            state.fetchProductionLineListResult = action.payload;
            state.isFetchingProductionLineList = false;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setFetchProductionLineError: (state, action) => {
            state.fetchProductionLineListError = action.payload;
            state.isFetchingProductionLineList = false;
            state.fetchProductionLineListResult = null

        },
        setfetchServerErr: (state, action) => {
            state.fetchServerErr = action.payload
        },

        setIsFetchingProductionLine: (state, action) => {
            state.isFetchingProductionLineList = action.payload;
        },
    }
});

export const {

    setfetchServerErr,

    setFetchProductionLineResult,
    setFetchProductionLineError,
    setConnected,
    setIsFetchingProductionLine,

} = ProductionLineSlice.actions;

export const fetchProductionLineList = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: production_line_list_url,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data

    };
    dispatch(setIsFetchingProductionLine(true));
    const response = axios(config)
        .then(response => {
            dispatch(setFetchProductionLineResult(response.data));
        })
        .catch(err => {
            if (err.respons) {
                dispatch(setFetchProductionLineError(err.response.data.error));
            }
            else {
                dispatch(setfetchServerErr('Err'))
            }
        });

    return response.data;

}
export const AddnewProductionLine = (data) => async (dispatch, getState) => {
    const config = {
      method: "post",
      url: createProductionLine_url,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.token,
      },
      data,
    };
  
    const response = await axios(config)
      .then((response) => {
        message.success(response.data.message)
        dispatch(fetchProductionLineList());
      })
      .catch((error) => {
        message.error(error.response.data.message)
      });
    console.log(response);
    return response;
  };



export default ProductionLineSlice.reducer;