import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
// import Signin from './features/sigIn/signIn';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';
import './index.css';
import Dashboard from './features/dashboard/dashboard';
import Signin from './features/sigIn/signIn';
import ProductionLineList from './features/productionLineList/productionLineList';
import SingleProductionLine from './features/productionLine/productionLine';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/signin" element={<Signin />} />
          <Route path="/dashboard" element={<Dashboard />} >
            <Route exact path='production_line'>
              <Route exact path='list' element={<ProductionLineList />} />
              <Route exact path='list/:id' element={<SingleProductionLine />} />
            </Route>
          </Route>
          <Route path='*' element={<Dashboard />} />
          <Route exact path="/" element={<Signin />} />

        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
