import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Button,
  Divider,
  Row,
  Typography,
  Input,
  Select,
  Drawer,
  Layout,
  Form,
  Modal,
  QRCode,
  message,
  Card,
  Popconfirm,
  Tooltip,
  DatePicker
} from "antd";
import { Switch } from "antd";
import {
  CloseCircleOutlined,
  CheckCircleTwoTone,
  LoadingOutlined,
} from "@ant-design/icons";

import { deleteMachine, fetchMachine, updateMachine } from "./machineSlice";
import moment from "moment";
import dayjs from "dayjs";
import PrintQrComp from '../../comps/qrComp';
import { hostname } from '../../constants';

const { Text } = Typography;
const { Option } = Select;

const SingleMachine = ({ machineID, openDrawer, onClose }) => {

  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [state, setstate] = useState({
    switcher: false,
    disable: false,
    editSingleMachine: false,
  });

  const [edit, setEdit] = useState({
    id: "",
    name: "",
    production_line: {}, // Entire data of a production line
    recurring_breakdown_reason_history: [""],
    equipment_id: 123456,
    equipment_descriptor: "",
    equipment_local: "",
    manufacturer: "",
    model: "",
    serial_number: "",
    asset_number: "",
    functional_location: "",
    operating_location: "",
    psv_1: "",
    speed_1: "",
    psv_2: "",
    speed_2: "",
    construction_date: "",
    last_maintenance_date: ""
  });

  useEffect(() => {
    if (machineID) {
      dispatch(fetchMachine({ id: machineID }));
      setEdit({ ...edit, id: machineID });
    }
  }, [machineID]);


  const handelUpdateInfo = async () => {

    dispatch(updateMachine({ machine_id: machineID, edit })).then((res) => {
      dispatch(fetchMachine({ id: machineID }));

      setstate({
        ...state,
        switcher: false,
        disable: false,
        editSingleVariant: false,
      });
    });

  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const { Content } = Layout;

  const fetchMachineResult = useSelector((state) => state.machine?.fetchMachineResult);
  const isLoading = useSelector((state) => state.machine?.isLoading);

  return (
    <Drawer
      title="Machine"
      placement="right"
      onClose={onClose}
      open={openDrawer}
      closable={false}
      maskClosable={!state.switcher}
      size='large'
      extra={<div style={{ width: "264px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
        <Popconfirm

          title="Supprimer la machine"
          description="Voulez vous vraiment supprimer cette machine?"
          onConfirm={() => dispatch(deleteMachine({ id: machineID }))}
          onCancel={() => message.error("Suppression annulée")}
          okText="Oui"
          cancelText="Non"
        >
          <Button type="dashed" style={{ width: "40%" }}
            disabled={state.switcher}
          >
            Supprimer
          </Button>
        </Popconfirm>
        <Switch
          checkedChildren="Modifier"
          unCheckedChildren="Modifier"
          disabled={state.disable}
          checked={state.switcher}
          onChange={(e) => {

            setstate({
              ...state,
              switcher: true,
              disable: true,
              editSingleAdmin: true,
            });
            setEdit({
              ...edit,
              name: fetchMachineResult.data?.name,
              production_line: fetchMachineResult.data?.production_line._id,
              recurring_breakdown_reason_history: fetchMachineResult.data?.recurring_breakdown_reason_history,
              equipment_id: fetchMachineResult.data?.equipment_id,
              equipment_descriptor: fetchMachineResult.data?.equipment_descriptor,
              equipment_local: fetchMachineResult.data?.equipment_local,
              manufacturer: fetchMachineResult.data?.manufacturer,
              model: fetchMachineResult.data?.model,
              serial_number: fetchMachineResult.data?.serial_number,
              asset_number: fetchMachineResult.data?.asset_number,
              functional_location: fetchMachineResult.data?.functional_location,
              operating_location: fetchMachineResult.data?.operating_location,
              psv_1: fetchMachineResult.data?.psv_1,
              speed_1: fetchMachineResult.data?.speed_1,
              psv_2: fetchMachineResult.data?.psv_2,
              speed_2: fetchMachineResult.data?.speed_2,
              construction_date: fetchMachineResult.data?.construction_date,
              last_maintenance_date: fetchMachineResult.data?.last_maintenance_date,

            });
            console.log(edit);
          }}
        />
      </div>
      }
    >
      <Modal
        onCancel={handleCancel}
        closable
        open={isModalOpen}
        footer={null}
      >
        {fetchMachineResult && <PrintQrComp link={"machines/" + machineID} name={fetchMachineResult.data?.name} />}
      </Modal>
      <Row justify={"space-between"}>
        <Col span={24}>
          <Row
            gutter={[16, 16]}
            direction="vertical"
            align="center"
            style={{ width: "100%" }}
          >
            <Col span={24} style={{ display: "flex", justifyContent: "center" }}>

              {!isLoading && fetchMachineResult &&
                <QRCode
                  value={hostname + "machines/" + machineID}
                  name={fetchMachineResult.data?.name}
                  size={200}
                />
              }
            </Col>

            <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
              <Button type="dashed" onClick={() => setIsModalOpen(true)}>
                Aperçu et Impression
              </Button>
            </Col>
          </Row>

          <Row align="middle">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{
              background: "white", height: "100%", minHeight: "30vh", padding: 10
            }} >
              {!isLoading && fetchMachineResult ? (
                <>
                  <Content>
                    <Form layout="horizontal" style={{ marginTop: "12px" }}>
                      <Row>
                        <Col span={12}>
                          <Text>Nom de la machine :</Text>
                          <Form.Item style={{ width: "100%", justifyContent: "flex-start" }}>
                            {state.switcher ?
                              <Input
                                style={{ maxWidth: "90%" }}
                                value={edit.name}
                                onChange={(e) => { setEdit({ ...edit, name: e.target.value }); console.log("nik yemmak") }}
                              /> :
                              <Text strong style={{ fontSize: "15px" }}>
                                {fetchMachineResult.data?.name}
                              </Text>}
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Text>Identifiant :</Text>
                          <Form.Item style={{ width: "100%", justifyContent: "flex-start" }}>
                            {state.switcher ?
                              <Input
                                style={{ maxWidth: "90%" }}
                                value={edit.equipment_id}
                                onChange={(e) => setEdit({ ...edit, equipment_id: e.target.value })}
                              /> :
                              <Text strong style={{ fontSize: "15px" }}>
                                {fetchMachineResult.data?.equipment_id}
                              </Text>}
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={12}>
                          <Text>Descripteur</Text>
                          <Form.Item style={{ width: "100%", justifyContent: "center" }}>
                            {state.switcher ?
                              <Input
                                style={{ maxWidth: "90%" }}
                                value={edit.equipment_descriptor}
                                onChange={(e) => setEdit({ ...edit, equipment_descriptor: e.target.value })}
                              />
                              :
                              <Text strong style={{ fontSize: "15px" }}>
                                {fetchMachineResult.data?.equipment_descriptor}
                              </Text>
                            }
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Text>Nom Local :</Text>
                          <Form.Item style={{ width: "100%", justifyContent: "flex-start" }}>
                            {state.switcher ?
                              <Input
                                style={{ maxWidth: "90%" }}
                                value={edit.equipment_local}
                                onChange={(e) => setEdit({ ...edit, equipment_local: e.target.value })}
                              /> :
                              <Text strong style={{ fontSize: "15px" }}>
                                {fetchMachineResult.data?.equipment_local}
                              </Text>}
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={12}>
                          <Text>Fabricant :</Text>
                          <Form.Item style={{ width: "100%", justifyContent: "center" }}>
                            {state.switcher ?
                              <Input
                                style={{ maxWidth: "90%" }}
                                value={edit.manufacturer}
                                onChange={(e) => setEdit({ ...edit, manufacturer: e.target.value })}
                              />
                              :
                              <Text strong style={{ fontSize: "15px" }}>
                                {fetchMachineResult.data?.manufacturer}
                              </Text>
                            }
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Text>Modèle :</Text>
                          <Form.Item style={{ width: "100%", justifyContent: "flex-start" }}>
                            {state.switcher ?
                              <Input
                                style={{ maxWidth: "90%" }}
                                value={edit.model}
                                onChange={(e) => setEdit({ ...edit, model: e.target.value })}
                              /> :
                              <Text strong style={{ fontSize: "15px" }}>
                                {fetchMachineResult.data?.model}
                              </Text>}
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={12}>
                          <Text>Motif de panne récurrente</Text>
                          <Form.Item style={{ width: "100%", justifyContent: "center" }}>
                            {state.switcher ?
                              <Input.TextArea
                                style={{ maxWidth: "90%" }}
                                value={edit.recurring_breakdown_reason_history ? edit.recurring_breakdown_reason_history[0] : ""}
                                onChange={(e) => setEdit({ ...edit, recurring_breakdown_reason_history: [e.target.value] })}
                              /> :
                              <Text strong style={{ fontSize: "15px", whiteSpace: "pre-line" }}>
                                {fetchMachineResult.data?.recurring_breakdown_reason_history[0]}
                              </Text>}
                          </Form.Item>
                        </Col>

                        <Col style={{height:"fit-content"}} span={12}>
                          <Text>Usine / Ligne :</Text>
                          <Form.Item style={{ width: "100%", justifyContent: "flex-start" }}>
                            {state.switcher ?
                              <Tooltip title="Ce champs n'est pas modifiable. Veuillez recréer la machine dans la bonne ligne de production pour en changer les informations.">
                                <Text strong style={{ fontSize: "15px" }}>
                                  {fetchMachineResult.data?.production_line?.plant_line}
                                </Text>
                              </Tooltip> :

                              <Text strong style={{ fontSize: "15px" }}>
                                {fetchMachineResult.data?.production_line?.plant_line}
                              </Text>

                            }
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={12}>
                          <Text>Numéro de Série :</Text>
                          <Form.Item style={{ width: "100%", justifyContent: "flex-start" }}>
                            {state.switcher ?
                              <Input
                                style={{ maxWidth: "90%" }}
                                value={edit.serial_number}
                                onChange={(e) => setEdit({ ...edit, serial_number: e.target.value })}
                              /> :
                              <Text strong style={{ fontSize: "15px" }}>
                                {fetchMachineResult.data?.serial_number}
                              </Text>}
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Text>Numéro d'Actif</Text>
                          <Form.Item style={{ width: "100%", justifyContent: "center" }}>
                            {state.switcher ?
                              <Input
                                style={{ maxWidth: "90%" }}
                                value={edit.asset_number}
                                onChange={(e) => setEdit({ ...edit, asset_number: [e.target.value] })}
                              /> :
                              <Text strong style={{ fontSize: "15px", whiteSpace: "pre-line" }}>
                                {fetchMachineResult.data?.asset_number}
                              </Text>}
                          </Form.Item>
                        </Col>


                      </Row>

                      <Row>
                        <Col span={12}>
                          <Text>Localisation Fonctionnelle :</Text>
                          <Form.Item style={{ width: "100%", justifyContent: "flex-start" }}>
                            {state.switcher ?
                              <Input
                                style={{ maxWidth: "90%" }}
                                value={edit.functional_location}
                                onChange={(e) => setEdit({ ...edit, functional_location: e.target.value })}
                              /> :
                              <Text strong style={{ fontSize: "15px" }}>
                                {fetchMachineResult.data?.functional_location}
                              </Text>}
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Text>Lieu d'Exploitation :</Text>
                          <Form.Item style={{ width: "100%", justifyContent: "flex-start" }}>
                            {state.switcher ?
                              <Input
                                style={{ maxWidth: "90%" }}
                                value={edit.operating_location}
                                onChange={(e) => setEdit({ ...edit, operating_location: e.target.value })}
                              /> :
                              <Text strong style={{ fontSize: "15px" }}>
                                {fetchMachineResult.data?.operating_location}
                              </Text>}
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={12}>
                          <Text>PSV 1 :</Text>
                          <Form.Item style={{ width: "100%", justifyContent: "flex-start" }}>
                            {state.switcher ?
                              <Input
                                style={{ maxWidth: "90%" }}
                                value={edit.psv_1}
                                onChange={(e) => setEdit({ ...edit, psv_1: e.target.value })}
                              /> :
                              <Text strong style={{ fontSize: "15px" }}>
                                {fetchMachineResult.data?.psv_1}
                              </Text>}
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Text>Vitesse 1 [CON/min] :</Text>
                          <Form.Item style={{ width: "100%", justifyContent: "flex-start" }}>
                            {state.switcher ?
                              <Input
                                style={{ maxWidth: "90%" }}
                                value={edit.speed_1}
                                onChange={(e) => setEdit({ ...edit, speed_1: e.target.value })}
                              /> :
                              <Text strong style={{ fontSize: "15px" }}>
                                {fetchMachineResult.data?.speed_1}
                              </Text>}
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={12}>
                          <Text>PSV 2 :</Text>
                          <Form.Item style={{ width: "100%", justifyContent: "flex-start" }}>
                            {state.switcher ?
                              <Input
                                style={{ maxWidth: "90%" }}
                                value={edit.psv_2}
                                onChange={(e) => setEdit({ ...edit, psv_2: e.target.value })}
                              /> :
                              <Text strong style={{ fontSize: "15px" }}>
                                {fetchMachineResult.data?.psv_2}
                              </Text>}
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Text>Vitesse 2 [CON/min] :</Text>
                          <Form.Item style={{ width: "100%", justifyContent: "flex-start" }}>
                            {state.switcher ?
                              <Input
                                style={{ maxWidth: "90%" }}
                                value={edit.speed_2}
                                onChange={(e) => setEdit({ ...edit, speed_2: e.target.value })}
                              /> :
                              <Text strong style={{ fontSize: "15px" }}>
                                {fetchMachineResult.data?.speed_2}
                              </Text>}
                          </Form.Item>
                        </Col>
                      </Row>

                      <Text>Date de Construction</Text>
                      <Form.Item style={{ width: "100%", justifyContent: "center" }}>
                        {state.switcher ?
                          <DatePicker
                            allowClear
                            value={dayjs(edit.construction_date)}
                            presets={[
                              { label: 'Hier', value: dayjs().add(-1, 'd') },
                              { label: 'La semaine dernière', value: dayjs().add(-7, 'd') },
                              { label: 'Le mois dernier', value: dayjs().add(-1, 'month') },
                            ]}
                            onChange={(value, dateString) => setEdit({ ...edit, construction_date: dateString && moment(dateString).format() })}
                          /> :
                          <Text strong style={{ fontSize: "15px" }}>
                            {fetchMachineResult?.data.construction_date && moment(fetchMachineResult.data.construction_date).format("DD-MM-YYYY")}
                          </Text>}
                      </Form.Item>
                    </Form>
                    {state.disable && (
                      <Divider>
                        <div style={{ display: "flex", justifyContent: "center", marginTop: 33 }}>
                          <Button type="primary" style={{ marginRight: "10px", backgroundColor: "#4CAF50" }} onClick={handelUpdateInfo}>
                            Mettre à Jour <CheckCircleTwoTone twoToneColor="#52c41a" />
                          </Button>
                          <Button
                            type="primary"
                            style={{ marginLeft: "10px" }}
                            danger
                            onClick={() => {
                              setEdit({ ...edit });
                              setstate({ ...state, switcher: false, disable: false, editSingleAdmin: false });
                            }}
                          >
                            Annuler <CloseCircleOutlined twoToneColor="#eb2f96" />
                          </Button>
                        </div>
                      </Divider>
                    )}
                  </Content>

                </>
              ) : (
                <div style={{ height: "80vh" }}>
                  <LoadingOutlined
                    twoToneColor="#eb2f96"
                    style={{ fontSize: 24, marginLeft: "50%", marginTop: "50%" }}
                    spin
                  />
                </div>
              )}

            </Col>
          </Row>

        </Col>
      </Row >
    </Drawer>

  );
};
export default SingleMachine;