import { configureStore } from '@reduxjs/toolkit';
import dashboardReducer from '../features/dashboard/dashboardSlice';
import SignInReducer from '../features/sigIn/signInSlice';
import ProductionLineReducer from '../features/productionLineList/productionLineListSlice';
import SingleProductionLineReducer from '../features/productionLine/productionLineSlice';
import machinesReducer from '../features/machinesList/machinesListSlice';
import machineReducer from '../features/machine/machineSlice';


export const store = configureStore({
  reducer: {
    dashboard: dashboardReducer,
    signIn: SignInReducer,
    productionLine: ProductionLineReducer,
    singleProductionLine: SingleProductionLineReducer,
    machines: machinesReducer,
    machine: machineReducer,
  },
});
